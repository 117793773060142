
.box-cropper {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 378px;
  min-height: 378px;
}

.crop-container {
  margin:0 auto;
  width: 360px;
  height: 360px;

}
.crop-container .reactEasyCrop_Container{
  position: relative;
  width: 360px;
  height: 360px;
  margin:0 auto;
  border-radius: 3px;
}

.controls {
  margin-top: auto;
  width: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:24px 0 20px ;
}

.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 1px solid #3f51b5;
  border-bottom: 1px solid #3f51b5;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}
